<template>
  <div class="warpper">
    <div class="main">
      <img class="main_img" src="../assets/bg@2x.png">
      <div class="docker">
        <div class="docker_city">
          <!-- 城市选择 -->
          <div 
            class="docker_city_box">
            <div class="docker_select" @click="handleCityFrom">
              <span>
                出发城市
              </span>
              <div class="docker_select_return">
                <span>
                 {{postInfo.fromCity}}
                </span>
                <img src="@/assets/icon_3@2x.png">
              </div>
            </div>
            <div class="line"></div>
            <!-- 调出城市选择组件 -->
            <div class="select_fromcity_main" v-if="isSelCityFrom">
              <div class="select_box">
                <div class="select_box_province">
                  <ul class="select_box_province_ul">
                      <li
                        class="select_box_province_li"
                        v-for="(item,index) in selectProvList"
                        :key="index"
                        :class="{'inActive':isIndex == index }"
                        @click="selectProv(item,index)"
                      >
                      {{item.name}}
                      </li>
                  </ul>
                </div>
                <div class="ver_line"></div>
                <div class="select_box_city">
                  <ul class="select_box_city_ul">
                    <li
                      class="select_box_city_li"
                      v-for="(item,index) in selectCityList"
                      :key="index"
                      @click="selectCity(item)"
                    >
                    {{item}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="docker_select" @click="handleCityTo">
              <span>
                到达城市
              </span>
              <div class="docker_select_return">
                <span>
                  {{postInfo.toCity}}
                </span>
                <img src="@/assets/icon_3@2x.png">
              </div>
            </div>
            <!-- 调取到达城市组件 -->
            <div class="select_fromcity_main" v-if="isSelCityTo">
              <div class="select_box">
                <div class="select_box_province">
                  <ul class="select_box_province_ul">
                      <li
                        class="select_box_province_li"
                        v-for="(item,index) in selectProvList"
                        :key="index"
                        :class="{'inActive':isIndex == index }"
                        @click="selectProv(item,index)"
                      >
                      {{item.name}}
                      </li>
                  </ul>
                </div>
                <div class="ver_line"></div>
                <div class="select_box_city">
                  <ul class="select_box_city_ul">
                    <li
                      class="select_box_city_li"
                      v-for="(item,index) in selectCityList"
                      :key="index"
                      @click="selectCityTo(item)"
                    >
                    {{item}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="docker_content">
          <div class="docker_content_box">
            <div class="docker_select" @click="handlePet">
              <span>
                宠物品种
              </span>
              <div class="docker_select_return">
                <span>
                  {{petName}}
                </span>
                <img src="@/assets/icon_3@2x.png">
              </div>
            </div>
            <div class="select_pet_main" v-if="isSelPet">
              <div class="select_pet_box">
                <div class="select_pet_box_all">
                  <ul class="select_pet_box_all_ul">
                      <li
                        class="select_pet_box_all_li"
                        v-for="item in petDataList"
                        :key="item.id"
                        @click="selectPet(item)"
                      >
                      {{item.name}}
                      </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="line"></div>
            <div class="docker_select">
              <input
                v-model="petWeight"
                placeholder="请输入宠物重量" 
                class="docker_select_input"
              />
              <span>kg</span>
            </div>
            <div class="line"></div>
            <div class="docker_select" @click="handlePetType">
              <span>
                托运方式
              </span>
              <div class="docker_select_return">
                <span>
                  {{pt_d_type_name}} {{pt_d_type_desc}}
                </span>
                <img src="@/assets/icon_3@2x.png">
              </div>
            </div>
            <div class="line"></div>
            <!-- 调取托运方式组件 -->
            <div class="select_type_main" v-if="isSelPetType">
              <div class="select_type_box">
                <div class="select_type_box_all flex_left">
                  <ul class="select_type_box_all_ul">
                      <li
                        class="select_type_box_all_li"
                        v-for="(item,index) in petTypeList"
                        :key="index"
                        @click="selectPetType(item)"
                      >
                      {{item.type}}
                      </li>
                  </ul>
                </div>
                <div class="ver_line"></div>
                <div class="select_type_box_all flex_right">
                  <ul class="select_type_box_all_ul">
                    <li
                      class="select_type_box_all_li flex_right_li"
                      v-for="(item,index) in selectPetTypeChildList"
                      :key="index"
                      @click="selectPetTypeChild(item)"
                    >
                    <span class="way_1">{{item.name}}</span>
                    <span class="way_2">{{item.desc}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div 
              class="docker_select select_diable"
              v-if="selectCageBox"
            >
              <span>是否有航空箱</span>
              <div class="docker_select_return">
                <span>
                  {{petCageName}}
                </span>
                <img src="@/assets/icon_3@2x.png">
              </div>
            </div>
            <div 
              class="docker_select"
              @click="handlePetCage"
              v-else
            >
              <span>是否有航空箱</span>
              <div class="docker_select_return">
                <span>
                  {{petCageName}}
                </span>
                <img src="@/assets/icon_3@2x.png">
              </div>
            </div>
            <!-- 调出航空箱品种组件 -->
            <div class="select_cage_main" v-if="isSelPetCage">
              <div class="select_cage_box">
                <div class="select_cage_box_all">
                  <ul class="select_cage_box_all_ul">
                      <li
                        class="select_cage_box_all_li"
                        v-for="item in petCageList"
                        :key="item.id"
                        @click="selectPetCage(item)"
                      >
                      {{item.name}}
                      </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="docker_desc">
          <div class="docker_desc_box">
            <span>注意事项：本报价不含税，宠物运输只运输宠物，不携带其它周边物品，
            航空箱以及相应的笼具详情咨询客服报价，可以购买宠物运输保险，
            本保险由众安保险承保。</span>
          </div>
        </div>
        <div class="docker_price">
          <div class="docker_price_box">
            <div class="button_before" v-if="isButtonShow">
              <el-button
                class="price_button"
                round
                @click="getPrice"
              >
                <span>查询报价</span>
              </el-button>
            </div>
            <div class="button_after" v-else>
              <div class="button_after_left">
                <div class="button_after_price">
                  <span class="price_icon">￥</span>
                  <span class="price_num">{{price}}</span>
                </div>
                <span class="price_des">{{priceNote}}</span>
                <span class="price_des">具体详情请咨询客服</span>
              </div>
                <el-button
                  class="price_button"
                  round
                ><span>咨询客服</span></el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ShowToast :message="errorMessage" v-show="isToastShow" />
</template>

<script>
// import {ref} from 'vue'
import {post} from '@/utils/request'
import { cityDataEffect } from '@/components/selectData.js'
import ShowToast from '@/components/Toast.vue'

export default {
  name: 'HomeView',
  components:{ ShowToast },
  data(){
    return {
      isIndex:0,
      isSelCityFrom:false,
      isSelCityTo:false,
      isSelPet:false,
      isSelPetType:false,
      isSelPetCage:false,
      selectProvList:'',
      selectCityList:'',
      isButtonShow:true,
      selectCageBox:true,
      petDataList:'',
      petName:'',
      petWeight:'',
      petTypeList:'',
      pt_d_type_name:'',
      pt_d_type_desc:'',
      selectPetTypeChildList:'',
      petCageList:'',
      petCageName:'如随机托运请选择',
      price:'',
      priceNote:'',
      isToastShow:false,
      errorMessage:'',
      postInfo:{
        pet_name:'',
        fromCity:'',
        toCity:'',
        pt_type:'',
        pt_d_type:'',
        pet_cage:''
      }
    }
  },
  mounted() {
    const { cityData, petData, petType, petCage } = cityDataEffect()
    this.selectProvList = cityData
    this.petDataList = petData
    this.petTypeList = petType
    this.petCageList = petCage
  },
  methods:{
    handleCityFrom(){
      this.isSelCityFrom = !this.isSelCityFrom
      this.isSelCityTo = false
      this.isSelPet = false
      this.isSelPetType = false
      this.isSelPetCage = false
    },
    handleCityTo(){
      this.isSelCityTo = !this.isSelCityTo
      this.isSelCityFrom = false
      this.isSelPet = false
      this.isSelPetType = false
      this.isSelPetCage = false
    },
    handlePet(){
      this.isSelPet = !this.isSelPet
      this.isSelCityTo = false
      this.isSelCityFrom = false
      this.isSelPetType = false
      this.isSelPetCage = false
    },
    handlePetType(){
      this.isSelPetType = !this.isSelPetType
      this.isSelPet = false
      this.isSelCityTo = false
      this.isSelCityFrom = false
      this.isSelPetCage = false
    },
    handlePetCage(){
      this.isSelPetCage = !this.isSelPetCage
      this.isSelPet = false
      this.isSelCityTo = false
      this.isSelCityFrom = false
      this.isSelPetType = false
    },
    selectProv(item,index){
      this.selectCityList = item.child
      this.isIndex = index
    },
    selectCity(item){
      this.postInfo.fromCity = item
      this.isSelCityFrom = false
    },
    selectCityTo(item){
      this.postInfo.toCity = item
      this.isSelCityTo = false
    },
    selectPet(item){
      this.postInfo.pet_name = item.id
      this.petName = item.name
      this.isSelPet = false
    },
    selectPetType(item){
      this.selectPetTypeChildList = item.child
      this.pt_d_type_name = item.type
      this.postInfo.pt_type = item.id
      if(item.id == 2){
        this.selectCageBox = false
      }else{
        this.selectCageBox = true
        this.postInfo.pet_cage = 0
      }
    },
    selectPetTypeChild(item){
      this.pt_d_type_desc = item.name
      
      this.postInfo.pt_d_type = item.id
      this.isSelPetType = false
    },
    selectPetCage(item){
      this.petCageName = item.name
      this.postInfo.pet_cage = item.id
      this.isSelPetCage = !this.isSelPetCage
    },
    getPrice(){
      const handlePrice = async () => {
      const numreg = /^[0-9]*$/
      if ( this.postInfo.fromCity == '') {
          this.errorMessage = '请选择出发城市'
          this.isToastShow = true
            setTimeout(()=>{
              this.errorMessage = ''
              this.isToastShow = false
            },2000)
        } else if(this.postInfo.toCity == ''){
          this.errorMessage = '请选择到达城市'
          this.isToastShow = true
            setTimeout(()=>{
              this.errorMessage = ''
              this.isToastShow = false
            },2000)
        } else if (this.postInfo.pet_name == '' ){
          this.errorMessage = '请选择宠物品种'
          this.isToastShow = true
            setTimeout(()=>{
              this.errorMessage = ''
              this.isToastShow = false
            },2000)
        } else if (this.petWeight == '' || !numreg.test(this.petWeight) || this.petWeight >= 50){
          this.errorMessage = '请正确填写宠物体重'
          this.isToastShow = true
            setTimeout(()=>{
              this.errorMessage = ''
              this.isToastShow = false
            },2000)
        } else if ( this.postInfo.pt_type == '' || this.postInfo.pt_d_type == '') {
          this.errorMessage = '请选择托运方式'
          this.isToastShow = true
            setTimeout(()=>{
              this.errorMessage = ''
              this.isToastShow = false
            },2000)
        } else if (this.postInfo.pet_cage ==''){
          this.postInfo.pet_cage = 0
        } else {
          handlePrice()
        }
        try {
          const result = await post('/public/v1/askPrice', {
            pet_name: this.postInfo.pet_name,
            pt_type:this.postInfo.pt_type,
            pt_d_type:this.postInfo.pt_d_type,
            weight:this.petWeight,
            from_city:this.postInfo.fromCity,
            to_city:this.postInfo.toCity,
            pet_cage:this.postInfo.pet_cage
          })
          if (result?.code === '00') {
            this.price = result.result.price
            this.priceNote = result.result.note
            this.isButtonShow = false
          } else {
            this.errorMessage = result.message
            this.isToastShow = true
            setTimeout(()=>{
              this.errorMessage = ''
              this.isToastShow = false
            },3000)
          }
        } catch (e) {
          this.errorMessage = e.response.data.message
          this.isToastShow = true
            setTimeout(()=>{
              this.errorMessage = ''
              this.isToastShow = false
            },3000)
          }
      }
      handlePrice()
    }
  }
}
</script>

<style lang="scss" scoped>
.warpper{
  width: 100%;
  height: 100%;
  // background-color: #266aaa;
}
.main{
  width:100%;
  // height: 100%;
  position: absolute;
  &_img {
    width:100%;
    height: 100%;
    position: absolute;
  }
}
.docker {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 .26rem 0 .27rem;
}
.docker_city {
  width: 6.95rem;
  height: 2.49rem;
  margin-top:5.06rem;
  &_box{
    width: 5.61rem;
    height: 2.49rem;
    border: .01rem solid #fff;
    background: #fff;
    border-radius: .3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 .67rem;
  }
}

.docker_content{
  width: 6.95rem;
  height: 5.04rem;
  margin-top:.21rem;
  &_box{
    width:5.61rem;
    height: 5.04rem;
    border: .01rem solid #fff;
    background: #fff;
    border-radius: .3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 .67rem;
  }
}
.docker_desc{
  width: 6.95rem;
  height: 1.41rem;
  margin-top:.33rem;
  z-index:2;
  &_box{
    padding: 0 .35rem 0 .15rem;
    opacity:0.6;
    span {
      line-height: .38rem;
      font-size: .28rem;
      font-weight: 100;
      color:#fff;
    }
  }
}
.docker_price{
  width: 6.95rem;
  height: 1.98rem;
  margin-top: .5rem;
  // position: relative;
  // &_box{
  //   margin-top: .5rem;
  // }
}
.button_before{
  .price_button{
    width: 6.95rem;
    border-radius: .49rem;
    border:0;
    line-height: .98rem;
    background: #fff;
    span {
      font-size: .32rem;
      font-weight: 400;
      font-family: 'PingFang SC,PingFangSC-Regular';
      color:#333333;
    }
  }
}
.button_after{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &_left{
    margin-left:.32rem;
    margin-top:.15rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &_price{
    display: flex;
    flex-direction: row;
    margin-bottom:.17rem;
    .price_icon{
      font-size: .28rem;
      font-weight: 500;
      color:#fff;
      line-height: .38rem;
    }
    .price_num{
      font-size: .52rem;
      font-weight: 600;
      color:#fff;
      // line-height: .38rem;
    }
  }
  .price_des{
    font-size: .26rem;
    line-height: .38rem;
    color:#fff;
    opacity: 0.6;
    font-weight: 100;
    margin-bottom: 0.05rem;
  }
  .price_button{
    width: 3.4rem;
    height: .98rem;
    border-radius: .49rem;
    border:0;
    line-height: .98rem;
    background: #fff;
    span {
      font-size: .32rem;
      font-weight: 400;
      font-family: 'PingFang SC,PingFangSC-Regular';
      color:#333333;
    }
  }
}

.docker_select{
  // width: 6.95rem;
  width:100%;
  height: 1.24rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: .32rem;
  line-height: 1.24rem;
  &_check{
    border: none;
  }
  &_return {
    span {
      margin-right: .1rem;
    }
    img{
      width: .25rem;
      height: .25rem;
    }
  }
  &_input{
    width: 5.65rem;
    height: 1.24rem;
    line-height: 1.24rem;
    border: none;
  }
}
.docker_select_input:focus{
  outline:none;
}
.select_diable {
  // background: rgba(0,0,0,.65);
  color:#CCCCCC;
}
.line{
  width: 5.65rem;
  height: .01rem;
  border-top: .01rem solid #CCCCCC;
}

// 城市选择弹出框样式
.select_fromcity_main{
  width: 100%;
  height: 100%;
  margin: 0 0.26rem 0 0.27rem;
  position: relative;
  z-index:10;
}
.select_box{
  width: 5.61rem;
  height: 3rem;
  background: #eeeeee;
  box-shadow: 15px 15px 10px #ccc;
  border-radius: .3rem;
  display: flex;
  flex-direction: row;
  // position: fixed;
  // margin-top:-0.15rem;
}
.select_box_province{
  width: 2.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  // overflow: hidden;
  overflow-x: hidden;
  overflow-y:auto;
  &_ul{
    height: 3rem;
    line-height: .32rem;
  }
  &_li{
    margin: .25rem 0;
  }
}
.inActive{
  font-weight: 400;
  color:#266aaa;
}
.select_box_city{
  width: 2.8rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  overflow-x: hidden;
  overflow-y:auto;
  &_ul{
    height: 3rem;
    line-height: .32rem;
  }
  &_li{
    margin: .25rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.ver_line{
  height: 100%;
  border-right: 0.01rem solid #fff;
  width: .01rem;
  z-index:10;
}

// 宠物选择组件调用css
.select_pet_main{
  width: 100%;
  height: 100%;
  margin: 0 0.26rem 0 0.27rem;
  position: relative;
  z-index:10;
}
.select_pet_box{
  width: 5.61rem;
  // height: 3rem;
  background: #eeeeee;
  border-radius: .3rem;
  box-shadow: 15px 15px 10px #ccc;
  position: absolute;
  // margin-top:-0.15rem;
}
.select_pet_box_all{
  // width: 2.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  // overflow: hidden;
  overflow-x: hidden;
  overflow-y:auto;
  padding: 0.2rem 0;
  &_ul{
    // height: 3rem;
    line-height: .32rem;
  }
  &_li{
    font-size: 0.32rem;
    line-height: 0.48rem;
    padding-bottom: .05rem;
    // border-bottom: .01rem solid #ffa200;
    margin: 0.15rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
// 宠物托运选择组件调用css
.select_type_main{
  width: 100%;
  height: 100%;
  margin: 0 0.26rem 0 0.27rem;
  position: relative;
  z-index:10;
}
.select_type_box{
  width: 6rem;
  height: 3rem;
  display: flex;
  flex:row;
  background: #eeeeee;
  border-radius: .3rem;
  box-shadow: 10px 10px 10px #ccc;
  // position: fixed;
  justify-content: space-around;
  align-items: flex-start;
  // overflow-x: hidden;
  // overflow-y:auto;
  // margin-top:-0.15rem;
}
.select_type_box_all{
  // width: 2.8rem;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: space-evenly;
  // overflow: hidden
  &_ul{
    height: 3rem;
    line-height: .32rem;
    overflow-x: hidden;
    overflow-y:auto;
  }
  &_li{
    font-size: 0.32rem;
    line-height: 0.48rem;
    margin: 0.15rem .2rem;
    padding-bottom: .05rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.flex_right_li{
  border-bottom: 0.01rem solid #fff;
}
.flex_left{
  width: 2rem;
  padding-top:.01rem;
  align-items: center;
  li{
    margin-top: .2rem;
  }
}
.flex_right{
  width: 4rem;
  overflow-x: hidden;
  overflow-y:auto;
}
.way_1{
  color:#666;
  margin-bottom: .05rem;
}
.way_2{
  margin: .05rem;
}
// 航空箱选择组件调用css
.select_cage_main{
  width: 100%;
  height: 100%;
  margin: 0 0.26rem 0 0.27rem;
  position: relative;
  z-index:10;
}
.select_cage_box{
  width: 5.61rem;
  height: 2.2rem;
  display: flex;
  flex:row;
  background: #eeeeee;
  border-radius: .3rem;
  box-shadow: 10px 10px 10px #ccc;
  // position: fixed;
  justify-content: center;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y:auto;
  // margin-top:-0.15rem;
}
.select_cage_box_all{
  // width: 2.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  // overflow: hidden;
  overflow-x: hidden;
  overflow-y:auto;
  &_ul{
    // height: 3rem;
    line-height: .32rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &_li{
    font-size: 0.32rem;
    line-height: 0.40rem;
    margin: 0.15rem .2rem;
  }
}
</style>