<template>
  <div class="main">
    <!-- <select name="" v-model="prov" >
      <option :value="item" v-for="(item,id) in options" :key="id" @click="hand1(item)">{{item.prov}}</option>
    </select>
    <select name="" v-model="city">
      <option value="item" v-for="(item,index) in cityList" :key="index">{{item}}</option>
    </select> -->
    <div  @check="handle">123</div>
      <input type="text" name="" id="">
      <ul @check="handle">
        <li v-for="(item,index) in options" :key="index" @click="handle(item)">{{item.name}}</li>
      </ul>

    <!-- <el-cascader-panel :options="options2" /> -->
  </div>
</template>

<script>

export default {
  name:'IndexView',
  data(){
    return {
      prov:'',
      cityList:[],
      city:'',
      options:[{
        id:1,
        name: '河北省',
        child: ['石家庄市', '唐山市', '秦皇岛市']
      },{
        id:2,
        name: '四川省',
        child: ['成都市', '自贡市']
      }],
      options2:[{
        value: '河北省',
        label: '河北省',
        children: [
          {
            value: '石家庄市',
            label: '石家庄市',
        },{
            value: '唐山市',
            label: '唐山市',
        }]
      },{
        value: '四川省',
        label: '四川省',
        children: [
          {
            value: '成都市',
            label: '成都市',
        },{
            value: '自贡市',
            label: '自贡市',
        }]
      }]
    }
  },
  methods:{
    handle(item){
      // console.log('ok')
      console.log(item.child)
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  height: 16rem;
  width: 7.5rem;
  background: #6e6e6e;
  padding-top:.3rem;
}

</style>