export const cityDataEffect = () => {
    const cityData = [
        {
          name:'北京市',
          child:['北京']
        },
        {
          name:'天津市',
          child:['天津']
        },
        {
          name: '河北省',
          child: ['石家庄', '唐山', '秦皇岛','邯郸','邢台','保定','张家口','承德','沧州','廊坊', '衡水']
        },
        {
            name: '山西省',
            child: ['太原','大同','阳泉','长治','晋城','朔州', '忻州','吕梁','晋中','临汾', '运城',]
        },
        {
            name: '内蒙古',
            child: ['呼和浩特','包头', '乌海','赤峰','呼伦贝尔','兴安盟', '通辽','鄂尔多斯','锡林郭勒盟', '乌兰察布盟','伊克昭盟','巴彦淖尔盟','阿拉善盟',]
        },
        {
            name: '辽宁省',
            child: [ '沈阳', '大连','鞍山','抚顺','本溪','丹东','锦州','营口','阜新','辽阳', '盘锦','铁岭','朝阳','葫芦岛',]
        },
        {
            name: '吉林省',
            child: ['长春', '吉林', '四平','辽源','通化','白山','松原','白城','延边朝鲜族自治州']
        },
        {
            name: '黑龙江省',
            child: ['哈尔滨','齐齐哈尔','双鸭山','鸡西', '大庆','伊春','牡丹江','佳木斯','七台河','黑河','绥化','大兴安岭地区',]
        },
        {
            name: '上海市',
            child: ['上海','上海虹桥','上海浦东']
        },
        {
            name: '江苏省',
            child: ['南京', '苏州', '无锡','常州','镇江','南通','泰州', '扬州','盐城','连云港','徐州','淮安','宿迁']
        },
        {
            name: '浙江省',
            child: ['杭州','宁波', '温州','嘉兴','湖州','绍兴','金华','衢州','舟山','台州','丽水']
        },
        {
            name: '安徽省',
            child: ['合肥','芜湖','蚌埠','淮南','马鞍山','淮北','铜陵','安庆', '黄山','滁州', '阜阳','宿州','巢湖','六安', '亳州','池州', '宣城']
        },
        {
            name: '福建省',
            child: ['福州','厦门','莆田','三明','泉州','漳州','南平','龙岩','宁德']
        },
        {
            name: '江西省',
            child: ['南昌','景德镇','萍乡','九江','新余','鹰潭','赣州', '吉安','宜春', '抚州','上饶']
        },
        {
            name: '山东省',
            child: [ '济南', '青岛', '淄博', '枣庄', '东营', '烟台','潍坊', '济宁', '泰安', '威海','日照','莱芜','临沂','德州', '聊城','滨州', '菏泽']
        },
        {
            name: '河南省',
            child: ['郑州', '开封','洛阳','平顶山', '安阳','鹤壁','新乡','焦作','许昌', '漯河','三门峡', '南阳', '商丘', '信阳','周口', '驻马店','焦作']
        },
        {
            name: '湖北省',
            child: ['武汉', '黄石', '十堰','荆州','宜昌', '襄樊','鄂州','荆门','孝感', '黄冈','咸宁', '随州', '恩施土家族苗族自治州','仙桃','天门','潜江','神农架林区']
        },
        {
            name: '湖南省',
            child: [ '长沙', '株洲', '湘潭', '衡阳','邵阳', '岳阳', '常德','张家界', '益阳','郴州','永州','怀化', '娄底', '湘西土家族苗族自治州']
        },
        {
            name: '广东省',
            child: [ '广州','深圳','东莞','中山','潮州','揭阳', '云浮','珠海', '汕头','韶关', '佛山','江门', '湛江', '茂名','肇庆', '惠州','梅州','汕尾','河源','阳江', '清远']
        },
        {
            name: '广西',
            child: ['南宁', '柳州', '桂林','梧州', '北海','防城港','钦州','贵港', '玉林','百色','贺州', '河池','来宾', '崇左']
        },
        {
            name: '海南省',
            child: ['海口','三亚', '五指山', '琼海','儋州','文昌','万宁','东方', '澄迈县','定安县','屯昌县', '临高县', '白沙黎族自治县','昌江黎族自治县','乐东黎族自治县','陵水黎族自治县','保亭黎族苗族自治县', '琼中黎族苗族自治县']
        },
        {
            name: '重庆市',
            child: ['重庆']
        },
        {
            name: '四川省',
            child: [ '成都','自贡','攀枝花','泸州','德阳','绵阳', '广元','遂宁','内江','乐山','南充','眉山','宜宾', '广安', '达州','雅安','巴中','资阳','阿坝藏族羌族自治州','甘孜藏族自治州','凉山彝族自治州']
        },
        {
            name: '贵州省',
            child: ['贵阳','六盘水','遵义', '安顺','铜仁地区', '毕节地区', '黔西南布依族苗族自治州', '黔东南苗族侗族自治州', '黔南布依族苗族自治州']
        },
        {
            name: '云南省',
            child: ['昆明','曲靖','玉溪', '保山','昭通','丽江', '普洱','临沧','德宏傣族景颇族自治州','怒江傈僳族自治州','迪庆藏族自治州','大理白族自治州', '楚雄彝族自治州','红河哈尼族彝族自治州', '文山壮族苗族自治州','西双版纳傣族自治州',]
        },
        {
            name: '西藏',
            child: ['拉萨','那曲地区','昌都地区','林芝地区','山南地区', '日喀则地区','阿里地区']
        },
        {
            name: '陕西省',
            child: ['西安','铜川','宝鸡','咸阳','渭南', '延安','汉中','榆林','安康', '商洛']
        },
        {
            name: '甘肃省',
            child: ['兰州','嘉峪关', '金昌','白银', '天水','武威', '酒泉', '张掖','庆阳','平凉','定西','陇南','临夏回族自治州','甘南藏族自治州','兰州中川']
        },
        {
            name: '青海省',
            child: [ '西宁','海东地区','海北藏族自治州','海南藏族自治州', '黄南藏族自治州', '果洛藏族自治州', '玉树藏族自治州', '海西蒙古族藏族自治州']
        },
        {
            name: '宁夏',
            child: ['银川', '石嘴山','吴忠','固原','中卫']
        },
        {
            name: '新疆',
            child: ['乌鲁木齐','克拉玛依','吐鲁番地区','哈密地区','和田地区','阿克苏地区','喀什地区','克孜勒苏柯尔克孜自治州','巴音郭楞蒙古自治州','昌吉回族自治州', '博尔塔拉蒙古自治州','石河子','阿拉尔','图木舒克','五家渠','伊犁哈萨克自治州']
        }
    ]

    const petData = [{
      id:1,
      name:'宠物狗'
    },{
      id:2,
      name:'宠物猫'
    },{
      id:3,
      name:'其他宠物'
    }]

    const petType = [
      {
        id:1,
        type:'宠物专车',
        child:[{
          id:1,
          name:'方式①：',
          desc:'上门提宠 - 送宠上门'
        },{
          id:2,
          name:'方式②：',
          desc:'上门提宠 - 城市网点'
        },{
          id:3,
          name:'方式③：',
          desc:'城市网点 - 城市网点'
        },{
          id:4,
          name:'方式④：',
          desc:'城市网点 - 送宠上门'
        }]
      },{
        id:2,
        type:'随机托运',
        child:[{
          id:1,
          name:'方式①:',
          desc:'手续 + 陪同'  
        }]
      },{
        id:3,
        type:'单独空运',
        child:[{
          id:1,
          name:'方式①：',
          desc:'上门提宠 - 送宠上门'
        },{
          id:2,
          name:'方式②：',
          desc:'上门提宠 - 城市网点'
        },{
          id:3,
          name:'方式③：',
          desc:'城市网点 - 城市网点'
        },{
          id:4,
          name:'方式④：',
          desc:'城市网点 - 送宠上门'
        }]
      }
    ]

    const petCage = [
      {
        id:0,
        name:'已有航空箱'
      },
      {
        id:1,
        name:'1号航空箱'
      },{
        id:2,
        name:'2号航空箱'
      },{
        id:3,
        name:'3号航空箱'
      },{
        id:4,
        name:'4号航空箱'
      }]
    return { cityData, petData, petType, petCage }
}