import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import 'lib-flexible/flexible.js'
import 'normalize.css'
import './base.scss'

// 完整引入Element Plus
import ElementPlus from 'element-plus'
// 注册el-ui图标库
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 全局配置国际化-中文
import zhCn from 'element-plus/es/locale/lang/zh-cn'

const app = createApp(App)
app.use(router)
app.use(store)
app.use(ElementPlus, {
  locale: zhCn,
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.mount('#app')
