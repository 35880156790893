<template>
  <div class="toast">{{message}}</div>
</template>

<script>
export default {
  name:'ShowToast',
  props: ['message']
}
</script>

<style lang="scss" scoped>
.toast {
  width: 5rem;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: .1rem;
  background: rgba(0, 0, 0, .65);
  border-radius: .05rem;
  color: #FFF;
  line-height: .48rem;
  text-align: center;
}
</style>